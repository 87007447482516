import type { GatsbySSR } from "gatsby";
import * as React from "react";
import { ToastContainer } from "react-toastify";

export const wrapRootElement: GatsbySSR<
  PageData,
  PageContext
>["wrapRootElement"] = ({ element }) => {
  return (
    <>
      <ToastContainer icon={false} theme="colored" />
      {element}
    </>
  );
};
