import type { ColorModesScale, Theme } from "theme-ui";

const colors: ColorModesScale = {
  text: "#000",
  background: "#fff",
  background1: "hsl(0 0% 96% / 1)",
  primary: "#ff8c00",
  accent: "#ffa500",
  muted: "#eaeaea",
  highlight: "#de0505",
};

const theme: Theme = {
  config: {
    useCustomProperties: true,
    initialColorModeName: "light",
  },
  colors,
  // borders: {
  //   highlight: `5px solid ${colors.accent}`,
  // },
  breakpoints: ["40em", "52em", "64em", "70em"],
  space: [
    0,
    "calc(var(--rhythm)/8)",
    "calc(var(--rhythm)/4)",
    "calc(var(--rhythm)/2)",
    "var(--rhythm)",
    "calc(var(--rhythm)*2)",
    "calc(var(--rhythm)*3)",
    "calc(var(--rhythm)*4)",
    "calc(var(--rhythm)*8)",
    "calc(var(--rhythm)*16)",
  ],
  fontSizes: [
    "0.618em",
    "1em",
    "1.618em",
    "2.618em",
    "4.236em",
    "6.854em",
    "11.089em",
  ],
  buttons: {
    transparent: {
      bg: "transparent",
      color: "text",
      fontSize: "16px",
      border: (_theme: Theme) => `1px solid ${_theme.colors?.muted}`,
      ":hover": {
        bg: "background1",
        boxShadow: "none",
      },
      ":focus": {
        bg: "background1",
        boxShadow: (_theme: Theme) => `0 0 0 1px ${_theme.colors?.accent}`,
      },
    },
  },
  borders: {
    disclosure: {
      border: (_theme: Theme) => `1px solid ${_theme.colors?.muted}`,
    },
  },
};

export default theme;
